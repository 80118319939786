<template>
    <div class="reset-password">
        <img src="@/assets/images/prosafe-new-logo.svg" alt="" width="357" height="50">

        <!-- Code verification module -->
        <VerifyCodeForm v-if="selectedForm === 'verifyCode'" @set-view="setView" />

        <!-- New password form -->
        <NewPasswordForm :user-id="userId" v-if="selectedForm === 'newPassword'" @set-view="setView" />

        <!-- Final message to redirect customer to home -->
        <FinalMessage v-if="selectedForm === 'finalMessage'" />
    </div>
</template>

<script setup>
import VerifyCodeForm from '../Components/VerifyCodeForm.vue'
import NewPasswordForm from '../Components/NewPasswordForm.vue'
import FinalMessage from '../Components/FinalMessage.vue'
import { ref } from 'vue'

const selectedForm = ref('verifyCode')
const userId = ref(null)

function setView(view) {
    selectedForm.value = view.setView
    userId.value = view.userId

    console.log(view)
}
</script>