import { httpRequests } from "../../../helpers/HttpRequests"

export function resetPasswordServices() {
    const { postRequest, putRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function verifyCodeService(code) {
        return postRequest('/reset_password/check_reset_code', code)
    }

    function resetPasswordService(passwordModel) {
        return putRequest('/reset_password/update_user_password', passwordModel)
    }

    return { verifyCodeService, resetPasswordService, errors }
}