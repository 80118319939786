<template>
    <div class="reset-password__final-message">
        <p>
            You can now access Safehub <br> 
            using your new password 
        </p>

        <RouterLink to="/login" class="button button--bordered">
            <img src="@/assets/icons/vuesax/linear/send-sqaure-2.svg" width="20" height="20" alt="">

            Redirect To Sign In
        </RouterLink>
    </div>
</template>